/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 26, 2021 */



@font-face {
    font-family: 'charlevoix_problack';
    src: url('charlevoixpro-black-webfont.woff2') format('woff2'),
         url('charlevoixpro-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_probold';
    src: url('charlevoixpro-bold-webfont.woff2') format('woff2'),
         url('charlevoixpro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_proextrabold';
    src: url('charlevoixpro-extrabold-webfont.woff2') format('woff2'),
         url('charlevoixpro-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_prolight';
    src: url('charlevoixpro-light-webfont.woff2') format('woff2'),
         url('charlevoixpro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_promedium';
    src: url('charlevoixpro-medium-webfont.woff2') format('woff2'),
         url('charlevoixpro-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_proregular';
    src: url('charlevoixpro-regular-webfont.woff2') format('woff2'),
         url('charlevoixpro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charlevoix_prothin';
    src: url('charlevoixpro-thin-webfont.woff2') format('woff2'),
         url('charlevoixpro-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}